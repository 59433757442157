import { DOCUMENT } from '@angular/common';
import { Component, AfterViewInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { getAnalytics, logEvent } from '@angular/fire/analytics';

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

@Component({
  selector: 'app-rebuilding-dialog',
  templateUrl: './rebuilding-dialog.component.html',
  styleUrls: ['./rebuilding-dialog.component.scss'],
  exportAs: 'modal'
})
export class RebuildingDialogComponent implements AfterViewInit {
  @ViewChild('modal')
  rebuildingDialog: ElementRef<HTMLDivElement>;

  private analytics = getAnalytics();

  private rbModal;
  constructor(
  ) { }

  ngAfterViewInit(): void {
    this.rbModal = UIkit.modal(this
      .rebuildingDialog
      .nativeElement
    );
  }

  show(target: string) {
    logEvent(this.analytics, 'opened_rebuilding_dialog', {target});
    this.rbModal.show();
  }
}
