<div class="wave">
</div>
<div class="page">
  <div class="stage">
    <header class="header">
      <a
        class="logo"
        href="/">
        <img src="assets/images/logo-white.png" />
      </a>
      <a
      class="cta"
      (click)="rbModal.show('join-us')"
      title="Join Us">
      Join Us
      </a>
      <!-- <a
        class="blog"
        href="https://medium.com/with-strangers"
        target="_blank">
        Blog
      </a> -->
    </header>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer>
    <div class="stage">
      <form
        action="https://withstrangers.us20.list-manage.com/subscribe/post?u=9d35d1ad485267a65fb65c889&amp;id=64dc770f70"
        method="post" id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate members-form" target="_blank" novalidate>
        <h2>Get Updates</h2>
        <div class="input-wrapper">
          <input
            type="email"
            placeholder="Enter Your Email Address"
            value="" name="EMAIL" class="required email" id="mce-EMAIL" />
          <span class="note hidden-on-small">
            Your email will <strong>never</strong> be sold or given away.<br />
            It will only ever be used to provide you with updates on our progress, and you can unsubscribe at any time.
          </span>
        </div>
        <button
          class="inline-cta"
          type="submit">
          Count Me In!
        </button>
        <span class="note small-only">
          Your email will <strong>never</strong> be sold or given away.<br />
          It will only ever be used to provide you with updates on our progress, and you can unsubscribe at any time.
        </span>
      </form>
      <div class="social-bar">
        <div class="logo-wrapper">
          <a
            class="logo"
            href="/">
            <img src="assets/images/logo-white.png" />
          </a>
        </div>
        <div class="social-links">
          <a
            href="https://www.facebook.com/connectwithstrangers"
            class="facebook"
            title="Connect with us on Facebook"
            target="_blank">
            <img src="assets/icons/svg/facebook.svg" />
          </a>
          <a
            href="https://twitter.com/_withstrangers"
            class="twitter"
            title="Follow us on Twitter"
            target="_blank">
            <img src="assets/icons/svg/twitter.svg" />
          </a>
          <a
            href="https://www.instagram.com/_withstrangers"
            class="instagram"
            title="Join us on Instagram"
            target="_blank">
            <img src="assets/icons/svg/instagram.svg" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</div>
<app-rebuilding-dialog #rbModal="modal"></app-rebuilding-dialog>