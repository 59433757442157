import { Component, OnInit } from '@angular/core';

interface Feature {
  slug: string;
  icon: string;
  label: string;
  description: string;
  comingSoon?: boolean;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  features: Feature[] = [
    {
      slug: 'unlockable',
      icon: 'unlockable',
      label: 'Unlockable',
      description: 'Access additional features as your connection with your match grows and proves itself'
    },
    {
      slug: 'definitions',
      icon: 'sharable',
      label: 'Shared Definitions',
      description: 'Set agreed upon definitions to ensure your conversations are built upon a shared understanding of the topics'
    },
    {
      slug: 'resources',
      icon: 'share-resources',
      label: 'Share Resources',
      description: 'Attach documents and links to your conversation for easy reference when you need to share additional information or third-party resources to help explain your perspective'
    },
    {
      slug: 'safety',
      icon: 'safety-and-quality',
      label: 'Safety And Quality',
      description: 'We provide multiple tools to ensure you are matched with only the highest quality people, and if you ever feel uncomfortable you can stop the conversation in a single click.'
    },
    {
      slug: 'introductions',
      icon: 'introduce-people',
      label: 'Introduce People',
      comingSoon: true,
      description: 'Made multiple amazing connections? Share the strangeness and introduce them to one another.'
    },
  ];

  howItWorks = [
    {
      label: 'Sign Up',
      illustration: 'profile',
      slug: 'sign-up',
      description: 'Tell us a little bit about you, your background, and some topics that fascinate you.',
    },
    {
      label: 'Get Matched',
      illustration: 'match',
      slug: 'get-matched',
      description: 'We connect you with people who have similar interests, but see them from a different perspective'
    },
    {
      label: 'Make Connections!',
      illustration: 'connection',
      slug: 'make-connections',
      description: 'Start talking. Build relationships and begin to see your world from a new point of view.'
    }
  ];

  why = [
    {
      label: 'Escape The Echo Chamber',
      illustration: 'escape',
      slug: 'escape',
      description: 'Break free from algorithms that are designed to profit by creating ever deepening wedges between you and others.',
    },
    {
      label: 'Gain A New Perspective',
      illustration: 'both-sides',
      slug: 'both-sides',
      description: 'Learn to see your own interests and passions from a new perspective. See science, literature, travel, or anything else through someone else\'s eyes.'
    },
    {
      label: 'Build Connections',
      illustration: 'build-connections',
      slug: 'build-connections',
      description: 'Create relationships and expand your horizons.'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
