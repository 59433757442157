<div class="hero">
  <div class="column text">
    <h4 class="greeting">Hello Stranger!</h4>
    <h1 class="headline">It&rsquo;s Time To Break Out</h1>
    <p class="subheadline">
      Connect with people who share your interests, but see them from a world away.
    </p>
  </div>
  <div class="column img">
    <img src="assets/images/illustrations/hero-break-out.png" />
  </div>
</div>
<div class="floater-dot small center"></div>
<div class="why block">
  <h2>What&rsquo;s In It For Me?</h2>
  <div class="block-cards">
    <div
      *ngFor="let card of why"
      class="why card"
      [class]="card.slug">
      <div class="img-wrap">
        <img class="illustration" [src]="'assets/images/illustrations/' + card.illustration + '.svg'" />
      </div>
      <div class="text">
        <h3>{{card.label}}</h3>
        <h4>{{card.description}}</h4>
      </div>
    </div>
  </div>
  <div class="cta-bar">
    <a
      (click)="rbModal.show('dive-in')"
      class="inline-cta">
      Ready? Dive In
    </a>
  </div>
</div>
<div class="how-it-works block">
  <h2>How It Works</h2>
  <div class="block-cards">
    <div
      *ngFor="let card of howItWorks"
      class="hiw card"
      [class]="card.slug">
      <div class="img-wrap">
        <img class="illustration" [src]="'assets/images/illustrations/' + card.illustration + '.svg'" />
      </div>
      <div class="text">
        <h3>{{card.label}}</h3>
        <h4>{{card.description}}</h4>
      </div>
    </div>
  </div>
</div>
<div class="features block">
  <h2>Features</h2>
  <div class="block-cards">
    <div
      *ngFor="let feature of features"
      class="feature card"
      [class]="feature.slug">
      <img class="icon" [src]="'assets/icons/svg/' + feature.icon + '.svg'" />
      <h3>{{feature.label}}</h3>
      <p>{{feature.description}}</p>
    </div>
  </div>
</div>
<div class="free block">
  <h2>
    <span>100% Free. </span>
    <span>No Fine Print.</span>
  </h2>
  <h3>
    No fancy words or "escape clauses" here. We will <em>never</em>:
  </h3>
  <ul>
    <li>
      Charge for you to use the platform
    </li>
    <li>
      Sell (or give away) your data
    </li>
    <li>
      Show you ads
    </li>
  </ul>
  <h3>End of story. We believe you are neither the customer <em>nor</em> the product. You are a person (strange as you might be) and we will treat you as such.</h3>
  <p>Curious how we make money? Us too!</p>
  <p>But seriously, right now we don't make money, but if you want to know some of our plans for how that might occur, you can <a href="mailto:connect@withstrangers.io?subject=Making+Money">email us directly</a> and we'll be happy to share our thoughts.</p>
</div>
<div class="cta block prove-them-wrong">
  <h3>
    Modern media constantly demands that we see our differences as divisive
  </h3>
  <h2>
    Let's prove them wrong
  </h2>
  <div class="cta-bar">
    <a
    (click)="rbModal.show('prove-them-wrong')"
    class="inline-cta">
    Let's Get Started!
  </a>
  </div>
  <p>
    If, for any reason, you are unsatisfied with your matches or any other part of the platform, you can delete your account (and all your data) at any time.
  </p>
</div>
<div class="who-we-are columns block">
  <h2 class="small-only">Who We Are</h2>
  <div class="column img">
    <img src="assets/images/illustrations/welcome.svg" />
  </div>
  <div class="column text">
    <h2 class="hidden-on-small">Who We Are</h2>
    <p>
      With Strangers is a platform designed to help facilitate those connections. To make it easier to meet people from all around the world, or perhaps in your own neighborhood, who might share a common interest, such as science or literature, but have a wealth of perspective about that interest that is entirely different from your own.
    </p>
    <p>
      This work isn't easy, but it is important, and we're hopeful you're ready to help us build this together.
    </p>
    <a
      (click)="rbModal.show('work-isnt-easy')"
      class="inline-cta">
      Let's Do It!
    </a>
  </div>
</div>
<app-rebuilding-dialog #rbModal="modal"></app-rebuilding-dialog>