// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDpNmvomVDGBNdbjInkTpVxyyXhXYB9oJg",
    authDomain: "dev.connect.withstrangers.io",
    databaseURL: "https://with-strangers-dev.firebaseio.com",
    projectId: "with-strangers-dev",
    storageBucket: "with-strangers-dev.appspot.com",
    messagingSenderId: "761497361326",
    appId: "1:761497361326:web:bc1f48dfc3a35cce47463f",
    measurementId: "G-H6GZLJ9CLP"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
