<div #modal uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <h2 class="uk-modal-title">
      We're Just Getting Started
    </h2>
    <p>
      Hey there!
      I'm so glad you're interested in joining With Strangers, but we're not quite live yet.
      Our first focus is building a community of people who yearn to understand others around them, not <em>inspite</em> of their differences, but <strong>because</strong> of them!
      <!-- You can read the full history and new trajectory here -->
    </p>
    <p>
      If you'd like to be informed about updates and when we'll fully launch, drop your email below.
      If you want to be <strong>a part of shaping that community</strong> and platform, check one of the boxes below and I'll reach out personally to get you connected to our community of Strangers crafting the next version of this platform!
    </p>
    <p>
      Thanks again, we can't wait to connect soon!
    </p>
    <app-mailing-list-form></app-mailing-list-form>
    <h3>In addition, join us on social media!</h3>
    <div class="social-bar">
      <a
        href="https://www.facebook.com/connectwithstrangers"
        class="facebook"
        title="Connect with us on Facebook"
        target="_blank">
        <img src="assets/icons/svg/facebook.svg" />
      </a>
      <a
        href="https://twitter.com/_withstrangers"
        class="twitter"
        title="Follow us on Twitter"
        target="_blank">
        <img src="assets/icons/svg/twitter.svg" />
      </a>
      <a
        href="https://www.instagram.com/_withstrangers"
        class="instagram"
        title="Join us on Instagram"
        target="_blank">
        <img src="assets/icons/svg/instagram.svg" />
      </a>
    </div>
  </div>
</div>