import { Injectable } from '@angular/core';

export interface ChatwootSettings {
  key: string;
  type?: string; // 'standard' | 'expanded_bubble';
  launcherTitle?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatwootService {
  constructor(
    private window: Window,
  ) {
    }

  init({key, type, launcherTitle}: ChatwootSettings) {
    if (type) {
      (this.window as any).chatwootSettings = {
        type,
        launcherTitle
      }
    }

    const BASE_URL = "https://app.chatwoot.com";
    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    g.src= BASE_URL + "/packs/js/sdk.js";
    s.parentNode.insertBefore(g,s);
    g.onload = () => {
      (this.window as any).chatwootSDK.run({
        websiteToken: key,
        baseUrl: BASE_URL,
      })
    }
  }
}
