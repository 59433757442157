import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

const CONTENT = {
  'rules-and-locked-features': {
    title: 'Rules And Locked Features',
    content: `<h2 id="why-are-some-features-locked">Why are some features locked?</h2><p>The purpose of the With Strangers platform is to connect you with people who see the world in different way than you do. That's not an easy task, and we're grateful to you for being willing to invest the effort. As such, much of our work designing the platform is focused on how to best facilitate that effort.</p><p>Because of that, many features are "locked" when you first make a connection. We believe that real connections have to start with simple, honest conversation. Once that foundation is laid, however, other features move from distractions to enhancements to further build your connection. </p><p>In order to avoid users trying to game the system, we don't publish the exact rules explicitly, but some examples of locked features that can be unlocked through real conversation include:</p><ul><li>Image Sharing</li><li>Video Chat</li><li>Mobile Messaging</li><li>And more</li></ul><p>We hope you find these "restrictions" helpful, rather than inhibiting, but if you find differently, or if you have ideas about other features that could be used to deepen your connections, <a href="mailto:alex@withstrangers.io?subject=Ideas about rules">reach out to me here</a></p>`,
  },
  'tools-for-connecting': {
    title: 'Tools For Connecting',
    content: `<p>This is our list of other tools and resources that we have found during our time seeking to connect with others. We'll be constantly updating this list, please let us know if you have any other suggestions, we'd be happy to add them here. Thanks, and happy connecting!</p><!--kg-card-begin: markdown--><ul>
    <li>Their Tube - <a href="https://www.their.tube/">https://www.their.tube/</a>
    <ul>
    <li>Better understand how social algorithms shape our viewpoints by looking at recommended video feed for users of different backgrounds</li>
    </ul>
    </li>
    <li>The Flip Side - <a href="https://theflipside.io/?rh_ref=8bb75c17">https://theflipside.io/</a>
    <ul>
    <li>Daily email breaking down one major issue from Right, Left, and now also Libertarian angles</li>
    </ul>
    </li>
    <li>Ground News - <a href="https://ground.news">https://ground.news</a>
    <ul>
    <li>Current events with multiple news sources (from across the spectrum) for each story so you can see how each perspective views, and reports on, the issue</li>
    </ul>
    </li>
    <li>Divided We Fall - <a href="https://dividedwefall.com">https://dividedwefall.com</a>
    <ul>
    <li>News and journalism focused on bridging the political divide within the United States</li>
    </ul>
    </li>
    <li>&quot;Talking To Strangers&quot; by Malcolm Gladwell
    <ul>
    <li>Amazon: <a href="https://www.amazon.com/Talking-To-Strangers-TPB/dp/024135157X">https://www.amazon.com/Talking-To-Strangers-TPB/dp/024135157X</a></li>
    <li>The audio book is structured like a podcast, complete with actual interviews, rather than simply someone reading the book. It's great!</li>
    </ul>
    </li>
    <li>LunchClub - <a href="https://lunchclub.ai/">https://lunchclub.ai/</a>
    <ul>
    <li>More focused on career and professional connections</li>
    </ul>
    </li>
    </ul>`,
  },
  'your-data': {
    title: 'Your Data',
    content: `<p>If you sign up for the With Strangers platform, you will notice we ask some fairly personal demographic questions. While all of these are optional, we do want to encourage you to fill out as many as you can, as the more we know about you, the better we can connect you with others. That said, in this age of social media and data harvesting, we want to assure you explicitly what we do and, more importantly, what we <strong>do not</strong> do with your data.</p><h2 id="what-we-do-with-your-data">What We Do With Your Data</h2><!--kg-card-begin: markdown--><ul>
    <li>Anonymize your profile
    <ul>
    <li>During our beta phase, all matches are manually reviewed before they are approved, however even that process is completely anonymous. Reviewers see <strong>only</strong> the profile values and not the users they are attached to.</li>
    </ul>
    </li>
    <li>Use profile values to create matches</li>
    <li>Collect <em>basic</em> conversation metrics to improve matching algorithm
    <ul>
    <li>This <strong>never</strong> includes the actual content of your conversation, but simply information such as the number of messages, what types of content were shared (text vs image vs video vs document), and how often you replied</li>
    <li>This is only used (again, anonymously) to help us evaluate the quality of the match so we can make them better over time</li>
    </ul>
    </li>
    </ul>
    <!--kg-card-end: markdown--><h2 id="what-we-don-t-do-with-your-data">What We Don't Do With Your Data</h2><!--kg-card-begin: markdown--><ul>
    <li>Sell or share it
    <ul>
    <li>No one outside of the WithStrangers platform will ever see your information. No advertisers (they'll never be here anyway). No <a href="https://withstrangers.io/sponsoring">sponsors</a>. No one.</li>
    </ul>
    </li>
    <li>Use it for any marketing/newsletter purposes
    <ul>
    <li>Your information is only ever used for improving your experience on the platform itself. If you sign up for our newsletter, that is entirely separate from your account and there will never be any overlap.</li>
    </ul>
    </li>
    </ul>
    <!--kg-card-end: markdown--><h2 id="where-it-is-stored">Where It Is Stored</h2><p>All information is encrypted and stored within the Google Cloud Platform. This is completely separate from all of Google's advertising and user tracking products. We simply use their database and authentication services to ensure our platform can scale automatically with demand and follow their best practices for securing your information to protect it from hackers or any other unwelcome third-parties.</p><h2 id="have-more-questions">Have more questions?</h2><p>Email us at <a href="mailto:connect@withstrangers.io?subject=Data">connect@withstrangers.io</a> with any questions or concerns and we'll get back to you directly!</p>`,
  },
  'profile-privacy-settings': {
    title: 'Profile Privacy Settings',
    content: `
    <h3>What Is Your Profile</h3>
    <p>The foundation of the With Strangers platform is your profile. It is what enables us to connect you with others who share your interests but see the world from a different perspective.</p>
    <p>Because we want to provide you with the best quality connections, many of the questions we ask are quite personal, which is why everything except your first name can be set to completely private (visible only to our matching algorithm) or even "undeclared" (not used in making matches at all).</p>
    <p>That said, we do encourage you to set as many values as you are comfortable with, however, as we can only build matches based on the information you share.</p>
    <h3>What Are Your Options</h3>
    <p>When filling out your profile, every value can be set to one of the following:
    <ul>
      <li><strong>Private</strong>: Used only for making matches, but not visible to anyone else</li>
      <li><strong>Match Only</strong> (<em>Default Setting</em>): Visible only to people you match with</li>
      <li><strong>Public</strong>: Currently no different than "Match Only," but will later be visible to anyone who visits your public profile page</li>
    </ul>
    </p>
    `
  },
  'conversation-features': {
    title: 'Conversation Features',
    content: `
      <h3>Small Ways To Connect Better</h3>
      <p>We recognize connecting to other people is hard, especially when you're starting from a limited understanding of each other's worldview. To help with that, we've built a few features into our platform specifically dedicated to facilitating those connections. This list is always growing, but a few examples include:
      <ul>
        <li><strong>Conversation prompts</strong>: Simply hit 'Request a prompt' and we'll suggest a topic. These will range from basic "getting to know you" questions at the start of your conversation to articles and questions about current events related to your shared interests. If you're not interested in the prompt that's suggested, simply dismiss it and request another.</li>
        <li><strong>Chat Only To Start</strong>: We realize video can help facilitate getting to know someone in a way pure chat cannot, however offering video at the beginning can also feel a bit uncomfortable or unsafe. As such, as your conversation continues, you will see <a href="/rules-and-locked-features">additional features</a> added to your conversation over time, including video chats and even the ability to suggest another connection to create a match manually</li>
        <li><strong>Shared Resources and Definitions</strong>: Share articles and links that you find helpful, or even work with your connection to create a shared definition for difficult terms to ensure you're not just talking past each other.</li>
      </ul>
    `
  },
  sponsoring: {
    title: 'Sponsoring',
    content: `<h2 id="thank-you">Thank You</h2><p>First off, the fact that you're even here, reading this, means the world to us. </p><h2 id="you-should-know">You Should Know</h2><p>Before we go any further, however, you should know:</p><h3 id="what-you-get">What you get</h3><ul><li>A huge thank you shared on all our social networks</li><li>A listing on our "Sponsors" page (not yet live, you could be the <strong>FIRST</strong>)</li><li>Randomly featured in the "This conversation sponsored by" widget within the Connect application once we launch it</li></ul><h3 id="what-you-don-t-get">What you don't get</h3><ul><li>Any user information (even "anonymized")</li><li>Any say in the direction/development of the With Strangers platform or application</li><li><em>Want some say?</em> We'd love to have you as part of the team, <a href="https://withstrangers.io/join-us">join us here</a></li></ul><h2 id="the-process">The Process</h2><p>Ok, now that we've cleared that up, right now the process is to simply email us at <a href="mailto:connect@withstrangers.io?subject=Sponsoring">connect@withstrangers.io</a> with the subject line 'Sponsoring' and we'll get back to you within 48 hours. Thanks again!</p>`
  },
  'join-us': {
    title: 'Join Us',
    content: `<p>So, we can't do this alone. And we'd love for you to join us! Below is a list of <s>all</s> a few of our current needs, but by all means reach out even if you don't feel like you fit any one exactly. We're just excited to meet you and work together on this and welcome whatever skills/insights/experiences/joy you bring.</p><h2 id="a-couple-things-you-should-know">A couple things you should know</h2><h3 id="this-is-100-bootstrapped">This is 100% bootstrapped</h3><p>Aka: Not Funded. Or, more accurately, since nothing in life is free, funded by my own modest bank account. I'm handling all the expenses, but at this point no one is getting paid, and it's likely to stay that way for a while. (If you want to help change that, see <a href="https://withstrangers.io/sponsoring">Sponsorships</a> or just email <a href="mailto:alex@withstrangers.io">me</a> directly.)</p><h3 id="this-is-new">This is new</h3><p>We're just getting started here, so every role is a bit of a "jack of all trades" type position, and you may be asked to do/try things outside your skillset. Excitingly, every decision you make will have resounding impacts on the entire future of this project and likely the organization that follows. (Want to know more about where we're going? Let's chat)</p><h3 id="this-is-big">This is big</h3><p>Or, at least, the vision is. As hinted at above, this project doesn't just end with a chat application, that's merely the start. You're welcome for a moment or for a lifetime, but be aware we're not starting this with the intention of playing around for a summer and moving on.</p><h2 id="that-said-come-on-in-the-water-is-fine-exciting">That said, come on in, the water is <s>fine</s> exciting</h2><!--kg-card-begin: html--><iframe class="airtable-embed" src="https://airtable.com/embed/shrF1F4Mwv8NKwZ97?backgroundColor=blue&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>`
  }
}

const EMBEDS = {
  terms: {
    url: 'https://app.termly.io/document/terms-of-use-for-saas/e81bc0b5-9e46-4b38-89ec-9dc9aacb4658',
    title: 'Terms & Conditions',
  },
  privacy: {
    url: 'https://app.termly.io/document/privacy-policy/4ceb46ff-10bd-438d-bd1c-539aa81410b7',
    title: 'Privacy Policy',
  }
}

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnInit {
  private _content: string;

  iframeUrl: SafeResourceUrl;
  header: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private title: Title,
  ) {
  }

  ngOnInit(): void {
    this
      .route
      .paramMap
      .subscribe((params) => {
        const slug = params.get('slug')
        if (slug && CONTENT[slug]) {
          this._content = CONTENT[slug].content
          this.title.setTitle(CONTENT[slug].title)
          this.header = CONTENT[slug].title
        } else if (slug && EMBEDS[slug]) {
          this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(EMBEDS[slug].url);
          this.title.setTitle(EMBEDS[slug].title)
          this.header = EMBEDS[slug].title
        } else {
          this.router.navigateByUrl('/')
        }
      })
  }

  get content() {
    if (this._content) {
      return this.sanitizer.bypassSecurityTrustHtml(this._content);
    } else {
      return '';
    }
  }

}
