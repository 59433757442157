import { BrowserModule } from '@angular/platform-browser';
import { isDevMode, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ContentPageComponent } from './pages/content-page/content-page.component';

import { environment } from '../environments/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/compat/remote-config';
import { RebuildingDialogComponent } from './rebuilding-dialog/rebuilding-dialog.component';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { MailingListFormComponent } from './mailing-list-form/mailing-list-form.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ContentPageComponent,
    RebuildingDialogComponent,
    MailingListFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // @angular/fire
    provideFirebaseApp(() =>  initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    AngularFireRemoteConfigModule,
  ],
  entryComponents: [
    HomePageComponent,
  ],
  providers: [
    // Analytics
    ScreenTrackingService,
    // Until we're done using compat libraries
    {
      provide: FIREBASE_OPTIONS,
      useValue: environment.firebase,
    },
    // Remote Config
    { provide: DEFAULTS, useValue: {
      chatwoot_key: '',
      chatwoot_type: 'standard',
      chatwoot_launcher_title: 'Welcome!',
    } },
    {
      provide: SETTINGS,
      useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}
    },

    // For Yousability
    { provide: Window, useValue: window }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
