import { Component, Inject, OnInit } from '@angular/core';
import {WINDOW} from '@ng-web-apis/common';

@Component({
  selector: 'app-mailing-list-form',
  templateUrl: './mailing-list-form.component.html',
  styleUrls: ['./mailing-list-form.component.scss']
})
export class MailingListFormComponent implements OnInit {

  constructor(
    @Inject(WINDOW)
    private window: Window
  ) { }

  ngOnInit(): void {
    (this.window as any).fd('form', {
      formId: '61eb68836114df05454c34a0',
      containerEl: '#fd-form-61eb68836114df05454c34a0'
    });
  }

}
