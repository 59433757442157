import { Component, OnInit } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { ChatwootService, ChatwootSettings } from './chatwoot.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'site';
  isMobile = false;

  constructor(
    private config: AngularFireRemoteConfig,
    private chatwoot: ChatwootService,
  ) {}

  ngOnInit() {
    // Force it to activate
    this
      .config
      .fetchAndActivate()
      .then(() => {
        this.config.getAll().then((config) => {
          if (config.chatwoot_key) {
            const settings: ChatwootSettings = {
              key: config.chatwoot_key.asString(),
              type: config.chatwoot_type.asString(),
              launcherTitle: config.chatwoot_launcher_title.asString(),
            }

            this.chatwoot.init(settings)
          }
        })
      })
  }
}
